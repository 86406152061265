<template>
  <Connect @getContract="getContract"/>
  <div class="select">
    <van-nav-bar
        title="Recipient Details"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="title">
        <div>Please ensure your details is</div>
        <div><span>accurate and complete</span></div>
      </div>
      <div class="caption">Delivery Details</div>
      <div class="list">
        <van-radio-group v-model="checked">
          <div v-for="(item,index) in addressList" :key="index" class="group">
            <div class="flex">
              <div class="left">
                <van-radio :name="item.id"/>
                <div class="name">{{ item.u_name }}</div>
              </div>
              <div class="edit" @click="router.push('/addAddress?edit=' + item.id)">Edit</div>
            </div>
            <div class="mobile">{{ item.mobile }}</div>
            <div class="mobile">{{ item.login_email }}</div>
          </div>
        </van-radio-group>
      </div>
      <div class="add" @click="router.push('/addAddress')">
        <img src="@/assets/imgs/icon_plus.png" alt="">
        <div class="text">Add New Address</div>
      </div>
      <div class="btnWrap">
        <div class="btn" @click="router.go(-1)">Cancel</div>
        <div class="btn" @click="handleConfirm">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Connect from '@/components/Connect'
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { getUserAddress } from '@/request/api'
  import { showFailToast, showToast, showSuccessToast} from 'vant'

  const checked = ref(0);
  const router = useRouter()
  const route = useRoute()
  const goods_id = route.query.goods_id

  const addressList = ref([])
  getList()

  // 确认购买
  let zuDisable = false
  async function handleConfirm() {
    if(!checked.value) {
      showToast('Please add an address')
      return
    }
    if(zuDisable) {
      return
    }
    zuDisable = true
    try {
      await blockNftContract.setApprovalForAll(contractConfig.conAddress, true, {
        gasPrice: gasPrice,
        gasLimit: 400000
      })
      const res = await conContract.Placeanorderfortheproduct(goods_id, checked.value, {
        gasPrice,
        gasLimit: 400000
      })
      if(res) {
        zuDisable = false
        setTimeout(() => {
          showSuccessToast('Operation Successful')
          router.push('/success')
        }, 2000)
      }
    } catch (e) {
      console.log(e)
      zuDisable = false
      showFailToast('Operation Failed')
    }
  }

  let conContract = null, blockNftContract = null, gasPrice = ''
  function getContract(obj) {
    conContract = obj.conContract
    blockNftContract = obj.blockNftContract
    gasPrice = obj.gasPrice
  }

  function getList() {
    getUserAddress({
      address: sessionStorage.getItem('address')
    }).then(res => {
      if(res.success) {
        addressList.value = res.data
        checked.value = res.data[0].id
      }
    })
  }
</script>

<style lang="less" scoped>
.select{


  .main{
    padding: 10px 24px 30px 24px;

    .title{
      color: #F8F8F8;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;

      span{
        color: #06C8A1;
      }
    }
    .caption{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 6px;
    }
    .list{
      margin-bottom: 32px;

      .group{
        font-size: 16px;
        padding: 15px 0;
        border-bottom: 1px solid #E8E7E7;

        .flex{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          .left{
            display: flex;
            align-items: center;

            .name{
              color: #F8F8F8;
              margin-left: 16px;
            }
          }
          .edit{
            color: #06C8A1;
          }
        }
        .mobile{
          color: #697377;
          line-height: 24px;
          padding-left: 36px;
        }
      }
    }
    .add{
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 22px;
      }
      .text{
        color: #F8F8F8;
        font-size: 16px;
        margin-left: 12px;
      }
    }
    .btnWrap{
      display: flex;
      justify-content: space-between;
      padding: 30px 46px;
      box-sizing: border-box;
      width: 100%;

      .btn{
        width: 92px;
        height: 36px;
        box-sizing: border-box;
        background: #697377;
        border-radius: 15px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn:hover{
        background: #06C8A1;
        border-color: #06C8A1;
      }
    }
  }
}
</style>
